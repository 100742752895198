<template>
		<Dialog header="Header" v-model:visible="mostrarDlg" :style="{width: '50vw'}" :modal="true" :closable="false" :closeOnEscape="true">
			<template #header>				
				Buscar Personas y/o Compañía
			</template>
				<div class="p-fluid p-field">
					<div class="p-inputgroup">
						<InputText id="filtro" type="text" placeholder="Escriba el criterio de búsqueda" v-model="filtroBusquedaEntidad" @keyup.enter="buscar" autofocus/>
						<Button icon="pi pi-search" @click="buscar"/>
					</div>
				</div>				
				<div class="p-fluid p-field">
					<div v-if="lkpEntidades.length > 0">
						<DataTable ref="resultados" :value="lkpEntidades" :scrollable="true" scrollHeight="flex" :loading="entidadDialogLoading"
						v-model:selection="selEntidad" selectionMode="single" dataKey="id_entidad" row-select="seleccionar">
						<Column field="entidad" header="Contacto" style="min-width:100px"></Column>
						<Column field="entidad_tipo" header="Tipo" style="min-width:300"></Column>
						<Column field="propietario" header="Propietario" style="min-width:100px" bodyStyle="text-align: center">
							<template #body="slotProps">							
								<i v-if="slotProps.data.propietario" class="pi pi-circle-on"></i>
								<i v-else class="pi pi-circle-off"></i>							
							</template>	
						</Column>
						<Column field="ocupante" header="Ocupante" style="min-width:100px" bodyStyle="text-align: center">
							<template #body="slotProps">							
								<i v-if="slotProps.data.ocupante" class="pi pi-circle-on"></i>
								<i v-else class="pi pi-circle-off"></i>							
							</template>							
						</Column>						
						<Column field="activo" header="Activo" style="min-width:100px" bodyStyle="text-align: center">
							<template #body="slotProps">							
								<i v-if="slotProps.data.activo" class="pi pi-circle-on"></i>
								<i v-else class="pi pi-circle-off"></i>							
							</template>								
						</Column>						
						</DataTable>				
					</div>
					<div v-else>
						<h5 style="margin: 15px auto; text-align: center;">No hay datos para mostrar</h5>
					</div>
				</div>			
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" @click="cancelar" class="p-button-text"/>
				<Button label="Seleccionar" icon="pi pi-check" @click="elegir" autofocus :disabled="entidadSelected"/>
			</template>
		</Dialog>
</template>

<script>
	import MdsService from '../service/mds.service';
	
	export default {
		name: 'MdsBuscarEntidad',
		props: {
			mostrar: {type: Boolean, required: true},
			filtro: {type: String, required: false}
		},
		data() {
				return {
					selEntidad: null, //this.entidad,
					entidadDialog: null,				
					entidadDialogLoading: false,
					filtroBusquedaEntidad: this.filtro,
					lkpEntidades: []
				}
		},
		computed: {
			mostrarDlg() {
				return this.mostrar;
			},
			entidadSelected() {
				return !(this.selEntidad);
			}		
		},
		methods: {
			mostrarResultadosBusqueda(filtro, resultados){
				this.filtroBusquedaEntidad = filtro;
				this.lkpEntidades = resultados;
			},
			cancelar() {
				this.visible = false;
				this.$emit('cancelar');				
			},
			seleccionar(){
				if(this.lkpEntidades.length == 1)
				{
					this.elegir();
				}
			},
			elegir() {				
				this.filtroBusquedaEntidad = '';

				this.entidadDialogLoading = true;
				MdsService.getEntidades(this.$store.state.auth.currentAppCtx, this.selEntidad.id_entidad).then(data=>{
					this.entidadDialogLoading = false;
					data.activoDisplay = (data.activo === 'S');
					this.$emit('seleccionar', data);
					this.lkpEntidades = [];
					this.selEntidad = null;
				}).catch(
					data => {
					this.entidadDialogLoading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}				
				);		
			},
			buscar() {
				this.entidadDialogLoading = true;
				this.selEntidad = null;
				
				MdsService.buscarEntidades(this.$store.state.auth.currentAppCtx, this.filtroBusquedaEntidad).then(data => 
				{
					this.lkpEntidades = data;
					this.entidadDialogLoading = false;

					// if(this.lkpEntidades.length > 0) {
					// 	this.$refs["resultados"].focus();
					// }					
				}	
				).catch(
					data => {
					this.entidadDialogLoading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}				
				);		
			}			
		}
	}
</script>

<style scoped>

</style>
