<template>
	<div class="vld-parent">
		<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col">
					<label>Código</label>
					<InputText :disabled="deshabilitado" v-model.trim="entidadData.codigo" autofocus />
				</div>
				<div class="p-field p-col">
					<label class="p-col" for="activo">Activo</label>
					<InputSwitch :disabled="deshabilitado" v-model="entidadData.activoDisplay"/>
				</div>
				</div>				
				<div class="p-field">
					<label for="id_entidad_tipo">Tipo</label>
					<Dropdown :disabled="deshabilitado" v-model="entidadData.id_entidad_tipo" required="true" :options="lkpEntidadesTipo" optionLabel="entidad_tipo" optionValue="id_entidad_tipo" placeholder="Seleccionar..." />
				</div>						
				<TabView>
					<TabPanel>
						<template #header>
						<i class="pi pi-globe p-m-1"></i>
						<span>Generales</span>
						</template>
						<div class="p-fluid">
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field">
								<label for="nombres">Nombres</label>
								<InputText :disabled="deshabilitado" v-model.trim="entidadData.nombres" />
							</div>					
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field">
								<label for="apellidos">Apellidos</label>
								<InputText :disabled="deshabilitado" v-model.trim="entidadData.apellidos" />
							</div>					
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field p-formgrid p-grid">
								<div class="p-field p-col">
									<label for="identificacion1">Identificación</label>
									<InputText :disabled="deshabilitado"  v-model.trim="entidadData.identificacion1" />
								</div>				
								<div class="p-field p-col">
									<label for="identificacion2">NIT</label>
									<InputText :disabled="deshabilitado"  v-model.trim="entidadData.identificacion2" />
								</div>											
							</div>											
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field p-formgrid p-grid">							
								<div  class="p-field p-col">
									<label for="tel_residencia">Teléfono</label>
									<InputText :disabled="deshabilitado"  v-model.trim="entidadData.tel_residencia" />
								</div>
								<div class="p-field p-col">
									<label for="tel_celular">Celular</label>
									<InputText :disabled="deshabilitado"  v-model.trim="entidadData.tel_movil" />
								</div>
							</div>	
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field p-formgrid p-grid">															
								<div class="p-field p-col">
									<label for="email1">Email</label>
									<InputText :disabled="deshabilitado"  v-model.trim="entidadData.email1" />
								</div>
								<div class="p-field p-col">
									<label for="email2">Email 2</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.email2" />
								</div>	
							</div>														
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field">
								<label for="direccion_residencia">Dirección</label>
								<InputText :disabled="deshabilitado"  v-model.trim="entidadData.direccion_residencia" />
							</div>
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field p-formgrid p-grid">															
								<div class="p-field p-col">
									<label for="tel_trabajo">Teléfono Trabajo 1</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.tel_trabajo" />
								</div>							
								<div class="p-field p-col">
									<label for="fax_trabajo">Teléfono Trabajo 2</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.fax_trabajo" />
								</div>
							</div>
							<div v-if="entidadData.id_entidad_tipo==1" class="p-field">
								<label for="direccion_trabajo">Dirección Trabajo</label>
								<InputText :disabled="deshabilitado" v-model.trim="entidadData.direccion_trabajo" />
							</div>
							
							<div v-if="entidadData.id_entidad_tipo==2" class="p-field">
								<label for="nombre_comercial">Compañia</label>
								<InputText :disabled="deshabilitado" v-model.trim="entidadData.nombre_comercial" />
							</div>					
							<div v-if="entidadData.id_entidad_tipo==2" class="p-field">
								<label for="razon_social">Razón Social</label>
								<InputText :disabled="deshabilitado" v-model.trim="entidadData.razon_social" />
							</div>
							<div v-if="entidadData.id_entidad_tipo==2" class="p-fieldp-formgrid p-grid">
								<div class="p-field p-col">
									<label for="tel_residencia">Teléfono</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.tel_trabajo" />
								</div>		
								<div class="p-field  p-col">
									<label for="fax_trabajo">Teléfono 2</label>
									<InputText :disabled="deshabilitado"  v-model.trim="entidadData.fax_trabajo" />
								</div>
							</div>	
							<div v-if="entidadData.id_entidad_tipo==2" class="p-field">
								<label for="direccion_trabajo">Dirección</label>
								<InputText :disabled="deshabilitado" v-model.trim="entidadData.direccion_trabajo" />
							</div>																								
							<div v-if="entidadData.id_entidad_tipo==2" class="p-field p-formgrid p-grid">
								<div class="p-field p-col">
									<label for="identificacion2">NIT</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.identificacion2" />
								</div>																			
								<div class="p-field p-col">
									<label for="email1">Email</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.email1" />
								</div>
							</div>
							<Fieldset v-if="entidadData.id_entidad_tipo==2" legend="Contacto o Representante">						
								<div class="p-field p-formgrid p-grid">
								<div class="p-field p-col">
									<label for="nombres">Nombres</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.nombres" />
								</div>					
								<div class="p-field p-col">
									<label for="apellidos">Apellidos</label>
									<InputText :disabled="deshabilitado" v-model.trim="entidadData.apellidos" />
								</div>									
								</div>
								<div class="p-field p-formgrid p-grid">
									<div class="p-field p-col">
										<label for="tel_trabajo">Teléfono</label>
										<InputText :disabled="deshabilitado" v-model.trim="entidadData.tel_residencia" />
									</div>							
									<div class="p-field p-col">
										<label for="tel_celular">Celular</label>
										<InputText :disabled="deshabilitado" v-model.trim="entidadData.tel_movil" />
									</div>
								</div>
								<div class="p-field p-formgrid p-grid">
									<div class="p-field p-col">
										<label for="identificacion1">Identificación</label>
										<InputText :disabled="deshabilitado" v-model.trim="entidadData.identificacion1" />
									</div>				
									<div class="p-field p-col">
										<label for="email2">Email</label>
										<InputText :disabled="deshabilitado" v-model.trim="entidadData.email2" />
									</div>								
								</div>
							</Fieldset>
						</div>	
					</TabPanel>
				</TabView>
			</div>
		</div>
	</div>
</template>

<script>
	// Import component
	import Loading from 'vue-loading-overlay';
	// Import stylesheet
	import 'vue-loading-overlay/dist/vue-loading.css';
	// import MdsService from '../service/mds.service';

	export default {
		name: 'MdsEntidad',
		data() {
			return {
				lkpEntidadesTipo: this.$store.state.auth.currentAppCtx.entidades_tipo,
				deshabilitado: this.disabled,
				entidadData: this.entidad,
				loading: false,
			}
		},
		props: {
			disabled: {
				type: Boolean,
				required: false,
				default: false
			},
			entidad: {
				type: Object,
				required: true,
				default() {
					return {
						id_entidad: "",
						id_entidad_tipo: 1,
						id_complejo: null,
						codigo: "",
						contacto: "",
						nombres: "",
						apellidos: "",
						identificacion1: "",
						identificacion2: "",
						tel_residencia: "",
						direccion_residencia: "",
						tel_movil: "",
						tel_trabajo: "",
						fax_trabajo: "",
						direccion_trabajo: "",
						email1: "",
						email2: "",
						nombre_comercial: "",
						razon_social: "",
						activo: "S",
						activoDisplay: true
					}
				}
			}			
		},		
		components:{
			Loading
		}
	}
</script>

<style scoped>

</style>
