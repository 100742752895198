<template>
	<div class="vld-parent">
		<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<!-- <img :src="'assets/demo/images/product/' + product.image" :alt="product.image" class="product-image" v-if="product.image" /> -->
				<Breadcrumb :model="rastromenu" />
				<h5>{{ tituloInmueble }}</h5>
				<div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col">
						<label for="codigo">Código</label>
						<InputText id="codigo" v-model="inmueble.codigo" autofocus />
					</div>
					<div class="p-field p-col">
						<label class="p-col" for="activo">Activo</label>
						<InputSwitch v-model="inmueble.activoDisplay"/>
					</div>
				</div>				
				<TabView @tab-click="onTabClick($event)">
					<TabPanel>
						<template #header>
						<i class="pi pi-globe p-m-1"></i>
						<span>Generales</span>
						</template>
						<div class="p-fluid">
							<div class="p-field">
								<label for="identificador1" class="p-text-bold">{{ complejo.nivel1 }}</label>
								<InputText id="identificador1" v-model="inmueble.identificador1" />
							</div>		
							<div v-if="complejo.nivel2 !=''" class="p-field">
								<label for="identificador2" class="p-text-bold">{{ complejo.nivel2 }}</label>
								<InputText id="identificador2" v-model="inmueble.identificador2" />
							</div>		
							<div v-if="complejo.nivel3 !=''" class="p-field">
								<label for="identificador3" class="p-text-bold">{{ complejo.nivel3 }}</label>
								<InputText id="identificador3" v-model="inmueble.identificador3" />
							</div>		
							<div v-if="complejo.nivel4 !=''" class="p-field">
								<label for="identificador4" class="p-text-bold">{{ complejo.nivel4 }}</label>
								<InputText id="identificador4" v-model="inmueble.identificador4" />
							</div>		
							<div v-if="complejo.nivel5!=''" class="p-field">
								<label for="identificador5" class="p-text-bold">{{ complejo.nivel5 }}</label>
								<InputText id="identificador5" v-model="inmueble.identificador5" />
							</div>																																	
							<div class="p-field">
								<label for="id_categoria">Clasificación</label>
								<Dropdown v-model="inmueble.id_clasificacion" :options="lkpClasificaciones" optionLabel="clasificacion" optionValue="id_clasificacion" placeholder="Seleccionar..." />
							</div>
							<div class="p-field">
								<label for="area">Area</label>
								<InputNumber id="area" v-model="inmueble.area" mode="decimal" :minFractionDigits="2" :maxFractionDigits="4"/>													
							</div>	
							<div class="p-field">
								<label for="notas">Notas</label>
								<InputText id="notas" v-model="inmueble.notas" />
							</div>	
							<div class="p-field p-inputgroup">
								<label class="p-m-2" for="ocupado">El inmueble se encuentra ocupado?</label>
								<InputSwitch class="p-m-2" id="ocupado" v-model="inmueble.ocupadoDisplay"/>	
							</div>										
							<Panel class="p-field" header="Emisión de Documentos">
								<div class="p-field">
									<label for="nombre_documento">Emitir Documento a nombre de</label>
									<InputText id="nombre_documento" v-model="inmueble.nombre_documento" />
								</div>
								<div class="p-field">
									<label for="nit_documento">NIT</label>
									<InputText id="nit_documento" v-model="inmueble.nit_documento" />
								</div>	
								<div class="p-field">
									<label for="direccion_documento">Dirección</label>
									<InputText id="notas" v-model="inmueble.direccion_documento" />
								</div>									
							</Panel>	
							<Panel class="p-field" header="Notificaciones">
								<template #icons>
									<i class="pi pi-envelope p-m-2"></i>
								</template>								
								<!-- <template #header>
									<i class="pi pi-envelope p-m-2"></i>
									<label class="p-m-2">Notificaciones</label>									 
								</template>								 -->
								<div class="p-field p-inputgroup">
									<label class="p-m-2" for="notificar_propietario">Enviar a Propietario?</label>
									<InputSwitch class="p-m-2" id="notificar_propietario" v-model="inmueble.notificarPropietarioDisplay"/>	
								</div>		
								<div class="p-field p-inputgroup">
									<label class="p-m-2" for="notificar_ocupante">Enviar a Ocupante?</label>
									<InputSwitch class="p-m-2" id="notificar_ocupante" v-model="inmueble.notificarOcupanteDisplay"/>	
								</div>			
							</Panel>							
						</div>	
					</TabPanel>
					<TabPanel :disabled="esNuevo">
						<template #header>
						<i class="pi pi-id-card p-m-1"></i>
						<span>Propietario</span>
						</template>
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button v-if="!inmueble.id_propietario" label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevoContacto(0)"/>				
								<Button v-if="!inmueble.id_propietario" label="Vincular Existente" icon="pi pi-plus" class="p-button-help p-mr-2" @click="buscarEntidad(0)"/>												
								<Button v-if="inmueble.id_propietario" label="Desvincular" icon="pi pi-plus" class="p-button-warning p-mr-2" @click="confirmarDesvinculacion(propietario, 0)"/>																				
							</template>
						</Toolbar>
						<MdsEntidad :entidad="propietario" :disabled="!inmueble.id_propietario" :key="propietarioUpdate"/>	
						<Button label="Guardar" icon="pi pi-check" class="p-button" @click="savePropietario" />							
					</TabPanel>
					<TabPanel :disabled="esNuevo">
						<template #header>
						<i class="pi pi-id-card p-m-1"></i>
						<span>Ocupante</span>
						</template>
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button v-if="!inmueble.id_ocupante" label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevoContacto(1)"/>				
								<Button v-if="!inmueble.id_ocupante" label="Vincular Existente" icon="pi pi-plus" class="p-button-help p-mr-2" @click="buscarEntidad(1)"/>												
								<Button v-if="inmueble.id_ocupante" label="Desvincular" icon="pi pi-plus" class="p-button-warning p-mr-2" @click="confirmarDesvinculacion(ocupante, 1)"/>																				
							</template>
						</Toolbar>			
						<MdsEntidad :entidad="ocupante" :disabled="!inmueble.id_ocupante" :key="ocupanteUpdate"/>	
						<Button label="Guardar" icon="pi pi-check" class="p-button" @click="saveOcupante" />														
					</TabPanel>
					<TabPanel :disabled="esNuevo">
						<template #header>
						<i class="pi pi-tag p-m-1"></i>
						<span>Planes de Servicio</span>
						</template>	
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevoPlanServicio" />
							</template>
						</Toolbar>
						<DataTable ref="planes" :value="planes_servicio" class="p-datatable-sm" dataKey="id_mov_plantilla" v-model:selection="plan" :paginator="true" :rows="5"								
								currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}">
							<Column field="plantilla" header="Plan" headerStyle="width: 30%"></Column>
							<Column field="plantilla_tipo" header="Tipo" headerStyle="width: 30%"></Column>
							<Column field="total" header="Monto" :sortable="true" headerClass="p-d-flex p-jc-end">
								<template #body="slotProps">
									<div v-if="slotProps.data.es_variable == 0" class="p-d-flex p-jc-end p-mr-2" sytle="font-weight: bold;">
										<div>{{ formatCurrency(slotProps.data.id_moneda, slotProps.data.total) }}</div>
									</div>
									<div v-else class="p-d-flex p-jc-end p-mr-2" sytle="font-weight: bold;">
											(Variable)
									</div>				
								</template>								
							</Column>
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-times" class="p-button-rounded p-button-outlined p-button-secondary p-mr-1" @click="confirmDeletePlantilla
									(slotProps.data)" />									
								</template>
							</Column>																												
						</DataTable>						
					</TabPanel>	
					<TabPanel :disabled="esNuevo">
						<template #header>
							<i class="pi pi-users p-m-1"></i>
							<span>Usuarios</span>	
						</template>		
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Button label="Nuevo" icon="pi pi-user" class="p-button-success p-mr-2" @click="nuevoUsuario" />
							</template>
						</Toolbar>																
						<DataTable ref="usuarios" :value="usuarios" class="p-datatable-sm" dataKey="id_inmueble_login" :paginator="true" :rows="5">
							<Column field="login" header="Usuario" headerStyle="width: 30%"></Column>
							<Column field="nombres" header="Nombres" headerStyle="width: 30%"></Column>
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-sign-out" class="p-button-rounded p-button-secondary p-mr-1" @click="confirmarDesasignarUsuario(slotProps.data)"/>
								</template>
							</Column>																	
						</DataTable>
					</TabPanel>							
					<TabPanel :disabled="esNuevo">
						<template #header>
						<i class="pi pi-list p-m-1"></i>
						<span>Historial</span>
						</template>							
						<DataTable ref="historial" :value="historial" class="p-datatable-sm" dataKey="id_inmueble_hist" :paginator="true" :rows="5">
						<Column field="fecha_registro" header="Fecha" headerStyle="width: 30%">
							<template #body="slotProps">
								{{slotProps.data.fecha_registro}}
							</template>
						</Column>
						<Column field="accion" header="Evento" headerStyle="width: 30%"></Column>
						<Column field="entidad" header="Contacto" headerStyle="width: 30%"></Column>
						<Column field="usuario" header="Usuario" headerStyle="width: 30%"></Column>													
						</DataTable>
					</TabPanel>												
				</TabView>
				<Button label="Guardar" icon="pi pi-check" class="p-button" @click="save" />

				<MdsBuscarEntidad :mostrar="buscarEntidadDlg" @cancelar="cancelarBuscarEntidadDlg" @seleccionar="seleccionEntidad" />

				<Dialog v-model:visible="deletePlanDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="plan">Esta seguro que desasignar el plan de servicio?<br><b>[{{ plan.plantilla }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePlanDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deletePlanServicio" />
					</template>
				</Dialog>		

				<Dialog v-model:visible="desvincularionDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="entidadaDesvincular && (desvinculacionTipo==0)">Esta seguro desvincular al propietario?<br><b>[{{ entidadaDesvincular.contacto }}]</b>?</span>
						<span v-if="entidadaDesvincular && (desvinculacionTipo==1)">Esta seguro desvincular al ocupante?<br><b>[{{ entidadaDesvincular.contacto }}]</b>?</span>						
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="desvincularionDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="desvincularEntidad(desvinculacionTipo)" />
					</template>
				</Dialog>								

				<Dialog v-model:visible="desvincularLoginDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span>Esta por desvincular a este usuario, desea continuar?<br><b>[{{ selLogin.nombres }}]</b></span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="desvincularLoginDlg = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="desasignarUsuario" />
					</template>
				</Dialog>		

				<Dialog v-model:visible="selPlanServicioDlg" :style="{width: '450px'}" header="Seleccionar Plan de Servicio" :modal="true">
					<DataTable ref="dt" :value="lkpPlanesServicio" v-model:selection="seleccionPlanes" dataKey="id_mov_plantilla" 
					:paginator="true" :rows="10"
					:rowsPerPageOptions="[5,10,25]"
					responsiveLayout="scroll" selectionMode="multiple"
					:metaKeySelection="false">
						<Column selectionMode="multiple" style="width: 3rem"></Column>
						<Column field="plantilla" header="Plan de Servicio" :sortable="true" ></Column>
						<Column field="plantilla_tipo" header="Tipo" :sortable="true"></Column>
					</DataTable>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="selPlanServicioDlg = false"/>
						<Button label="Aceptar" icon="pi pi-check" class="p-button-text" @click="registrarNuevosPlanes" />
					</template>
				</Dialog>

				<Dialog v-model:visible="selUsuarioDlg" :style="{width: '450px'}" header="Seleccionar Usuarios" :modal="true">
					<DataTable ref="dt" :value="usuariosDisponibles" v-model:selection="seleccionUsuarios" dataKey="login" 
					responsiveLayout="scroll" selectionMode="multiple"
					:metaKeySelection="false">
						<Column selectionMode="multiple" style="width: 3rem"></Column>
						<Column field="nombres" header="Nombres" :sortable="true" ></Column>
						<Column field="apellidos" header="Apellidos" :sortable="true"></Column>
					</DataTable>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="selUsuarioDlg = false"/>
						<Button label="Aceptar" icon="pi pi-check" class="p-button-text" @click="registrarNuevosUsuarios" />
					</template>
				</Dialog>												
			</div>
		</div>
	</div>

</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsEntidad from '../../components/MdsEntidad.vue';
import MdsBuscarEntidad from '../../components/MdsBuscarEntidad.vue';
import MdsService from '../../service/mds.service';


export default {
	data() {
		return {
			id_inmueble: this.id,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			lkpClasificaciones: this.$store.state.auth.currentAppCtx.inmuebles_clasificaciones,
			lkpPlanesServicio: null,
			propietarioUpdate: 0,
			rastromenu: [
				{label: 'Inmuebles', to: '/inmuebles'}
			],
			propietario: {
				id_entidad: null,
				id_entidad_tipo: 1,
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				codigo: "",
				contacto: "",
				nombres: "",
				apellidos: "",
				identificacion1: "",
				identificacion2: "",
				tel_residencia: "",
				direccion_residencia: "",
				tel_movil: "",
				tel_trabajo: "",
				fax_trabajo: "",
				direccion_trabajo: "",
				email1: "",
				email2: "",
				nombre_comercial: "",
				razon_social: "",
				activo: "S",
				activoDisplay: true							
			},
			ocupanteUpdate:0,
			ocupante: {
				id_entidad: null,
				id_entidad_tipo: 1,
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				codigo: "",
				contacto: "",
				nombres: "",
				apellidos: "",
				identificacion1: "",
				identificacion2: "",
				tel_residencia: "",
				direccion_residencia: "",
				tel_movil: "",
				tel_trabajo: "",
				fax_trabajo: "",
				direccion_trabajo: "",
				email1: "",
				email2: "",
				nombre_comercial: "",
				razon_social: "",
				activo: "S",
				activoDisplay: true			
			},
			selLogin: {
				login: "",
				nombres: ""
			},
			desvincularLoginDlg: false,
			usuarios: null,
			selUsuarioDlg: false,
			usuariosDisponibles: null,
			seleccionUsuarios: null,
			buscarEntidadDlg: false,		 	
			vinculandoTipo: 0,			
			entidadaDesvincular: null,
			desvinculacionTipo: 0,
			desvincularionDlg: false,
			planes_servicio: null,
			plan: null,
			selPlanServicioDlg: false,
			seleccionPlanes: null,
			deletePlanDlg: false,
			historial: null,
			loading: false,
			fullPageLoading: false,
			esNuevo: true,
			inmueble: {
				id_inmueble: "",
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				id_cta_habiente_info: "",
				id_clasificacion: "",
				codigo: "",
				identificador1: "",
				identificador2: "",
				identificador3: "",
				identificador4: "",
				identificador5: "",
				notas: "",
				id_propietario: null,
				notificar_propietario: "S",
				notificarPropietarioDisplay: true,
				id_ocupante: null,
				notificar_ocupante: "S",
				notificarOcupanteDisplay: true,			
				nombre_documento: "",
				nit_documento: "",
				direccion_documento: "",
				activo: "S",
				activoDisplay: true,			
				ocupado: "S",
				ocupadoDisplay: true,
				area: 0.0									
			}			
		}
	},
	components: {
		Loading, MdsEntidad, MdsBuscarEntidad
	},
	props: {
		id: {
			type: String,
			required: true,
			default: ''
			}			
	},		
	created(){		
		if(this.id != '')
		{
			this.fetchData();
		} else {
			this.openNew();
		}
	},
	computed: {
		tituloInmueble()
		{			
			let nivel1 = this.complejo.nivel1.trim() != '' ? this.complejo.nivel1.trim() + ' ' + this.inmueble.identificador1.trim(): "";
			let nivel2 = this.complejo.nivel2.trim() != '' ? this.complejo.nivel2.trim() + ' ' + this.inmueble.identificador2.trim(): "";
			let nivel3 = this.complejo.nivel3.trim() != '' ? this.complejo.nivel3.trim() + ' ' + this.inmueble.identificador3.trim(): "";
			let nivel4 = this.complejo.nivel4.trim() != '' ? this.complejo.nivel4.trim() + ' ' + this.inmueble.identificador4.trim(): "";
			let nivel5 = this.complejo.nivel5.trim() != '' ? this.complejo.nivel5.trim() + ' ' + this.inmueble.identificador5.trim(): "";
			
			let identif1 = this.inmueble.identificador1 ? this.inmueble.identificador1.trim(): ''; 
			let identif2 = this.inmueble.identificador2 ? this.inmueble.identificador2.trim(): ''; 
			let identif3 = this.inmueble.identificador3 ? this.inmueble.identificador3.trim(): ''; 
			let identif4 = this.inmueble.identificador4 ? this.inmueble.identificador4.trim(): ''; 
			let identif5 = this.inmueble.identificador5 ? this.inmueble.identificador5.trim(): ''; 

			let identificador = identif1 + identif2 + identif3 + identif4 + identif5;
			let titulo = nivel1.trim() + ' ' + nivel2.trim() + ' ' + nivel3.trim() + ' ' + nivel4.trim() + ' ' + nivel5.trim();
			
			if(identificador.trim() == '')
			{
				titulo = "<Sin Clasificar>";
			}

			return titulo.trim();
		},
	},
	methods: {
		onTabClick(event)
		{
			if((event.index == 1) && (!this.propietario.id_entidad)) {
				this.getPropietario();
			} else if((event.index == 2) && (!this.ocupante.id_entidad)) {
				this.getOcupante();
			} else if((event.index == 3) && (!this.planes_servicio)) {
				this.getPlanesServicio();
			} else if((event.index == 4) && (!this.usuarios)) {
				this.getUsuarios();
			} else if ((event.index == 5) && (!this.historial)){
				this.getHistorial();	
			}
		},
		openNew() {
			this.inmueble = {
				id_inmueble: "",
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				id_cta_habiente_info: "",
				id_clasificacion: "",
				codigo: "",
				identificador1: "",
				identificador2: "",
				identificador3: "",
				identificador4: "",
				identificador5: "",
				notas: "",
				id_propietario: null,
				notificar_propietario: "S",
				notificarPropietarioDisplay: true,
				id_ocupante: null,
				notificar_ocupante: "S",
				notificarOcupanteDisplay: true,		
				nombre_documento: "",
				nit_documento: "",
				direccion_documento: "",
				activo: "S",
				activoDisplay: true,			
				ocupado: "S",
				ocupadoDisplay: true,
				area: 0.0
			}
			this.esNuevo = true;
		},	
		formatCurrency(moneda, value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
			return;
		},					
		nuevoPlanServicio() {
			this.loading = true;

			MdsService.getPlanesServicio(this.$store.state.auth.currentAppCtx, '').then(data => {
				this.lkpPlanesServicio = data;
				this.loading = false;
				this.selPlanServicioDlg = true;
			}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},
		registrarNuevosPlanes() {
			this.selPlanServicioDlg = false;

			var promises = [];

			this.seleccionPlanes.forEach(element => {
				promises.push(
					MdsService.registrarInmueblePlanServicio(this.$store.state.auth.currentAppCtx, this.inmueble.id_inmueble, element.id_mov_plantilla).catch(
						data => {
							this.$toast.add({severity:'error', summary: 'Error', detail: data})
						}
					)
				);
			});

			this.loading = true;
			Promise.all(promises).then(() => {
					MdsService.getPlanesServicio(this.$store.state.auth.currentAppCtx, this.id_inmueble).then(data => {
						this.planes_servicio = data;
						this.loading = false;
					}).catch(
						data => {
							this.loading = false;
							this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
						}
					)
				}
			);
		},		
		async nuevoUsuario()
		{			
			try {				
				this.usuariosDisponibles = await MdsService.getLoginsDisponiblesInmueble(this.$store.state.auth.currentAppCtx, this.id_inmueble);				
				this.selUsuarioDlg = true;
			} catch(error) {
				this.selUsuarioDlg = false;
				console.log(error);
			}
		},
		async registrarNuevosUsuarios()
		{
			try {	
				this.selUsuarioDlg = false;
				this.loading = true;
				if(this.seleccionUsuarios) {
					for(var i=0; i < this.seleccionUsuarios.length; i++) {
						await MdsService.asignarLoginInmueble(this.$store.state.auth.currentAppCtx, this.inmueble, this.seleccionUsuarios[i]);
						this.usuarios.push(this.seleccionUsuarios[i]);
					}
				}
				
				this.seleccionUsuarios = [];

				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;				
			}
		},
		save() {						
			this.inmueble.activo = this.inmueble.activoDisplay ? 'S': 'N';
			this.inmueble.ocupado = this.inmueble.ocupadoDisplay ? 'S': 'N';			
			this.inmueble.notificar_propietario = this.inmueble.notificarPropietarioDisplay ? 'S': 'N';
			this.inmueble.notificar_ocupante = this.inmueble.notificarOcupanteDisplay ? 'S': 'N';			

			this.loading = true;
			MdsService.registrarInmueble(this.$store.state.auth.currentAppCtx, this.inmueble).then(data => {
				this.loading = false;
				this.inmueble.id_inmueble = data.id_inmueble;
				this.esNuevo = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},
		confirmDeletePlantilla(plantilla) {
			this.plan = plantilla;
			this.deletePlanDlg = true;			
		},
		nuevoContacto(tipo){
			if(tipo==0)
			{
				this.propietario = this.nuevaEntidad();
				this.propietario.id_entidad = "nuevo";
				this.inmueble.id_propietario = this.propietario.id_entidad;
				this.propietarioUpdate++;
			} else {
				this.ocupante = this.nuevaEntidad();
				this.ocupante.id_entidad = "nuevo";			
				this.inmueble.id_ocupante = this.ocupante.id_entidad;
				this.ocupanteUpdate++;				
			}
		},
		nuevaEntidad(tipo)
		{
			return {
				id_entidad: "",
				id_entidad_tipo: 1,
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				codigo: "",
				contacto: "",
				nombres: "",
				apellidos: "",
				identificacion1: "",
				identificacion2: "",
				tel_residencia: "",
				direccion_residencia: "",
				tel_movil: "",
				tel_trabajo: "",
				fax_trabajo: "",
				direccion_trabajo: "",
				email1: "",
				email2: "",
				nombre_comercial: "",
				razon_social: "",
				activo: "S",
				activoDisplay: true							
			};
		},
		savePropietario() {				
			this.propietario.id_entidad = this.propietario.id_entidad == "nuevo"? "": this.propietario.id_entidad;
			this.propietario.activo = this.propietario.activoDisplay ? 'S': 'N';
			this.loading = true;
			
			var promises = [];

			promises.push(
				MdsService.registrarEntidad(this.$store.state.auth.currentAppCtx, this.propietario).then(data => {
					this.propietario.id_entidad = data.id_entidad;						

					MdsService.InmuebleVincularEntidad(this.$store.state.auth.currentAppCtx, this.inmueble, this.propietario.id_entidad, 0).then(()=>{
						this.loading = false;
						this.inmueble.id_propietario = this.propietario.id_entidad;
					});
				})
			);

			this.loading = true;
			Promise.all(promises).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},	
		saveOcupante()
		{
			this.ocupante.id_entidad = this.ocupante.id_entidad == "nuevo"? "": this.ocupante.id_entidad;
			this.ocupante.activo = this.ocupante.activoDisplay ? 'S': 'N';
			this.loading = true;
			
			var promises = [];

			promises.push(
				MdsService.registrarEntidad(this.$store.state.auth.currentAppCtx, this.ocupante).then(data => {
					this.ocupante.id_entidad = data.id_entidad;						

					MdsService.InmuebleVincularEntidad(this.$store.state.auth.currentAppCtx, this.inmueble, this.ocupante.id_entidad, 1).then(()=>{
						this.loading = false;
						this.inmueble.id_ocupante = this.ocupante.id_entidad;
					});
				})
			);

			this.loading = true;
			Promise.all(promises).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},
		confirmarDesvinculacion(entidad, tipo)
		{
			this.entidadaDesvincular = entidad;
			this.desvinculacionTipo = tipo;
			this.desvincularionDlg = true;
		},	
		buscarEntidad(tipo) 
		{
			this.vinculandoTipo = tipo;
			this.buscarEntidadDlg = true;			
		},	
		cancelarBuscarEntidadDlg()
		{
			this.buscarEntidadDlg = false;
		},
		seleccionEntidad(Entidad)
		{		
			this.buscarEntidadDlg = false;									
			
			if(this.vinculandoTipo == 0) {
				this.propietario = Entidad;										
				this.inmueble.id_propietario = this.propietario.id_entidad;				
				this.propietarioUpdate++;
			} else {
				this.ocupante = Entidad;
				this.inmueble.id_ocupante = this.ocupante.id_entidad;							
				this.ocupanteUpdate++;
			}
		},
		desvincularEntidad(tipo)
		{			
			var promises = [];
			this.desvincularionDlg = false;

			promises.push(
				MdsService.InmuebleDesvincularEntidad(this.$store.state.auth.currentAppCtx, this.inmueble, tipo).then(()=>{
					if(tipo==0){
						this.inmueble.id_propietario = null;
						this.propietario = {};
						this.propietarioUpdate++;
					} else {
						this.inmueble.id_ocupante = null;
						this.ocupante = {};
						this.ocupanteUpdate++;
					}
				})
			);

			this.loading = true;
			Promise.all(promises).then(()=>{
				this.loading = false;
			}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)
		},
		deletePlanServicio() {
			this.loading = true;
			MdsService.removerPlanServicioInmueble(this.$store.state.auth.currentAppCtx, this.id_inmueble, this.plan.id_mov_plantilla).then(()=>{
				this.loading = false;
				this.planes_servicio  = this.planes_servicio.filter(val => val.id_mov_plantilla !== this.plan.id_mov_plantilla);
				this.plan = {};
				this.deletePlanDlg = false;
			}).catch(
				data => {
					this.loading = false;
					this.deletePlanDlg = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);		
		},
		getPlanesServicio() {
			this.loading = true;
			MdsService.getPlanesServicio(this.$store.state.auth.currentAppCtx, this.inmueble.id_inmueble).then(data => {
				this.loading = false;
				this.planes_servicio = data;
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			);
		},
		getUsuarios(){
			this.loading = true;
			MdsService.LoginsXInmueble(this.$store.state.auth.currentAppCtx, this.inmueble).then(data=>{
				this.loading = false;
				this.usuarios = data;
			}).catch(
					data => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
			);
		},
		getHistorial() {			
			this.loading = true;
			MdsService.getInmuebleHistorial(this.$store.state.auth.currentAppCtx, this.inmueble.id_inmueble).then(data => {
				this.loading = false;
				this.historial = data;
			}).catch(
					data => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
						this.historial = [];
					}
			);
		},		
		getPropietario() {
			this.loading = true;			
			MdsService.getEntidades(this.$store.state.auth.currentAppCtx, this.inmueble.id_propietario).then(data => {						
				this.loading = false;
				this.propietario = data;	
				this.propietario.activoDisplay = (this.propietario.activo === 'S');
				this.propietarioUpdate++;
			}).catch(
					data => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
			);
		},
		getOcupante() {
			this.loading = true;				
			MdsService.getEntidades(this.$store.state.auth.currentAppCtx, this.inmueble.id_ocupante).then(data => {						
				this.loading = false;	
				this.ocupante = data;						
				this.ocupante.activoDisplay = (this.ocupante.activo === 'S');
				this.ocupanteUpdate++;
			}).catch(
					data => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
			);
		},
		confirmarDesasignarUsuario(Login){
			this.selLogin = Login;
			this.desvincularLoginDlg =  true;
		},		
		desasignarUsuario(){
			this.loading = true;
			this.desvincularLoginDlg = false;			

			MdsService.removerLoginInmueble(this.$store.state.auth.currentAppCtx, this.inmueble, this.selLogin).then(()=>{
				this.loading = false;
				this.usuarios  = this.usuarios.filter(val => val.login !== this.selLogin.login);
				this.selLogin = {}
			}).catch(
					data => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
				)
		},
		fetchData () {
			this.error = this.post = null;
			this.id_inmueble = this.id;
			this.loading = true;
			MdsService.getInmuebles(this.$store.state.auth.currentAppCtx, this.id_inmueble).then(data => {								
				this.inmueble = data;
				this.inmueble.activoDisplay = (data.activo == 'S');
				this.inmueble.ocupadoDisplay = (data.ocupado == 'S');
				
				this.inmueble.id_propietario = data.id_propietario == '' ? null: data.id_propietario;						
				this.inmueble.id_ocupante = data.id_ocupante == '' ? null: data.id_ocupante;				

				this.inmueble.notificarPropietarioDisplay = (this.inmueble.notificar_propietario == 'S');
				this.inmueble.notificarOcupanteDisplay = (this.inmueble.notificar_ocupante == 'S');		
		
				this.esNuevo = false;

				this.loading = false;
			
				}).catch(
					data => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
				)
		}			
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
